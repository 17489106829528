import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../orders.css";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Numbers from "../components/Numbers";
import { Link } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAuth } from "../context/AuthContext";
const Orders = () => {
  const [count, setCount] = useState(1);
  const { navBool } = UserAuth();
  const notify = (x) =>
    toast.info(`Status Changed to ${x}`, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const handle = (i) => {
    setCurrentPage(i);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(15);
  const lastIndex = currentPage * postPerPage;
  const firstIndex = lastIndex - postPerPage;
  const [load, setLoad] = useState(true);

  const [data, setData] = useState([]);

  const getdata = () => {
    const q = query(collection(db, "orders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setData(list);

      setLoad(false);
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  const [search, setSearch] = useState("");

  const date2 = new Date();

  const sortedByTime = data.sort(
    (a, b) =>
      new Date(b.date.seconds * 1000).getTime() -
      new Date(a.date.seconds * 1000).getTime()
  );

  const ShowDate = (b) => {
    const date1 = b;
    const diffTime = Math.abs(date2.getTime() - date1.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffHrs = Math.ceil(diffTime / (1000 * 60 * 60));
    const diffMins = Math.ceil(diffTime / (1000 * 60));
    const monthDiff =
      date2.getMonth() -
      date1.getMonth() +
      12 * (date2.getYear() - date1.getYear());

    if (monthDiff > 0) {
      return monthDiff + " month";
    } else if (diffDays > 0 && diffHrs > 24) {
      return diffDays + " day";
    } else if (diffHrs > 0 && diffMins > 60) {
      return diffHrs + " hr";
    } else if (diffMins > 0 && diffMins < 60) {
      return diffMins + " min";
    }

    return b.toDateString();
  };
  return (
    <>
      <Navbar title="Order" />

      <Sidebar />

      <div className={navBool ? "OrdersMove" : "Orders"}>
        <div className="contain">
          <div className="count">
            <h2>All Orders</h2>
            <p>{data.length}</p>
          </div>

          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="box">
            <div className="search">
              <BsSearch className="icon" size={32} />
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
              />
              <button>Search</button>
            </div>
            <div className="OrderTable">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className="TableTitles">
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Customer</TableCell>
                      <TableCell align="left">Items</TableCell>

                      <TableCell align="left">Actions</TableCell>
                      <TableCell align="left">Total</TableCell>
                      <TableCell align="left">Delivery Address</TableCell>
                      <TableCell align="left">Coupon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {load ? (
                      <div class="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      data
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          } else if (
                            val.user?.name
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            val.user?.phone.includes(search) ||
                            val.order_id
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .slice(firstIndex, lastIndex)
                        .map((row) => {
                          console.log(row);
                          return (
                            <TableRow
                              key={row.order_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Link
                                  className="orderLink"
                                  to={`/orderdetail/${row.order_id}`}
                                >
                                  #{row.order_id.slice(0, 6)}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                {ShowDate(new Date(row.date.seconds * 1000))}
                              </TableCell>
                              <TableCell align="left">
                                <div className="CustomerContainer">
                                  <p>{row.user?.name}</p>
                                  <p>{row.user?.phone}</p>
                                </div>
                              </TableCell>

                              <TableCell align="left">
                                {row.items[0]?.name}
                              </TableCell>
                              <TableCell align="left">
                                <div>
                                  <select
                                    value={row.status}
                                    onChange={async (i) => {
                                      if (i.target.value == "Delivered") {
                                        await setDoc(
                                          doc(db, "orders", row.order_id),
                                          {
                                            ...row,
                                            status: i.target.value,
                                          }
                                        );
                                        row.items.map(async (i) => {
                                          const docRef = doc(
                                            db,
                                            "TopProducts",
                                            i.product_id
                                          );
                                          const docSnap = await getDoc(docRef);

                                          if (docSnap.exists()) {
                                            setDoc(
                                              doc(
                                                db,
                                                "TopProducts",
                                                i.product_id
                                              ),
                                              {
                                                ...i,
                                                counter:
                                                  docSnap.data().counter + 1,
                                              }
                                            );
                                          } else {
                                            setDoc(
                                              doc(
                                                db,
                                                "TopProducts",
                                                i.product_id
                                              ),
                                              {
                                                ...i,
                                                counter: 1,
                                              }
                                            );
                                          }
                                        });
                                      } else {
                                        await setDoc(
                                          doc(db, "orders", row.order_id),
                                          {
                                            ...row,
                                            status: i.target.value,
                                          }
                                        );
                                      }
                                      notify(i.target.value);
                                    }}
                                    name=""
                                    id=""
                                    style={{ padding: "6px 20px" }}
                                  >
                                    <option value="" disabled>
                                      --Select--
                                    </option>
                                    <option value="Pending">Pending</option>

                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Proccessing">
                                      Proccessing
                                    </option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Cancelled">Cancelled</option>
                                  </select>
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                {row.total_price}
                              </TableCell>

                              <TableCell align="left">
                                {row.deliveryAddress?.city}
                              </TableCell>

                              <TableCell align="left">
                                {row.coupon?.code == null
                                  ? "None"
                                  : row.coupon?.code}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <Numbers
              className="nb"
              rowLength={data.length}
              postPerPage={postPerPage}
              handle={handle}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;

import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../products.css";
import { BiPlusCircle } from "react-icons/bi";
import { GrCircleInformation } from "react-icons/gr";
import { db } from "../firebase";
import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { v4 } from "uuid";
import { AiOutlineDown } from "react-icons/ai";
import { BsChevronDown, BsShieldFillExclamation } from "react-icons/bs";
import { UserAuth } from "../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
const Products = () => {
  const [filename, setFileName] = useState("Choose File");
  const [imgFile, setImgFile] = useState("");
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handlefile = (event) => {
    var x = event.target.files[0].name;
    setFileName(x);
  };

  const [data, setData] = useState({
    name: "",
    price: "",
    category_id: "",
    video_link: "",
    images: [],
    cover_pic: "",
    id: "",
    discount_type: "none",
    discount: 0,
    discription: "",
    available_quantity: 0,
    isActive: true,
  });
  const ImageFileListFunction = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImageList((prevState) => [...prevState, newImage]);
    }
  };
  const VideoFileListFunction = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.files.length; i++) {
      const newVideo = e.target.files[i];
      newVideo["id"] = Math.random();
      setVideoList((prevState) => [...prevState, newVideo]);
    }
  };

  const AddData = (e) => {
    e.preventDefault();
    const id = v4();

    imageList.map((image) => {
      const imgListRef = ref(storage, `ProductImages/${image.name}`);

      const uploadTask = uploadBytesResumable(imgListRef, image);
      uploadTask.on(() => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
        });
      });
    });

    videoList.map((video) => {
      const videoListRef = ref(storage, `ProductVideos/${video.name}`);

      const uploadTask = uploadBytesResumable(videoListRef, video);
      uploadTask.on(() => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
        });
      });
    });
    if (!imgFile) {
      alert("Add a cover image");
      return;
    } else if (data.name == "") {
      alert("Add a name");
    } else if (data.price <= 0) {
      alert("Add a price");
    } else if (data.category_id == "") {
      alert("Please select a category");
    } else {
      const refs = doc(db, "products", id);
      const imgRef = ref(storage, new Date().toString());
      const uploadTask = uploadBytesResumable(imgRef, imgFile);
      uploadTask.on(
        (error) => {
          console.log("Error ");
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setDoc(refs, {
              name: data.name,
              price: parseFloat(data.price),
              category_id: data.category_id,
              video_link: data.video_link,
              images: data.images,
              cover_pic: downloadURL,
              id: id,
              discount_type: data.discount_type,
              discount: data.discount === null ? 0 : parseInt(data.discount),
              discription: data.discription,
              available_quantity:
                data.available_quantity === null
                  ? 0
                  : parseInt(data.available_quantity),
              isActive: data.isActive,
            }).catch((err) => {
              setError(true);
              setInterval(() => {
                setError(false);
              }, 4000);
            });
          });
        }
      );

      setSuccess(true);
      setInterval(() => {
        setSuccess(false);
      }, 4000);
    }
    setData({
      name: "",
      price: 0,
      category_id: "",
      videos: [],
      images: [],
      cover_pic: "",
      id: "",
      discount_type: "none",
      discount: 0,
      discription: "",
      available_quantity: 0,
      isActive: true,
    });
    setFileName("Choose File");
  };
  const getCategory = () => {
    getDocs(collection(db, "category")).then((res) => {
      setCategoryList(
        res.docs.map((i) => {
          return i.data();
        })
      );
    });
  };

  useEffect(() => {
    getCategory();
  }, []);
  const { navBool } = UserAuth();
  const [disabled, setDisabled] = useState(true);

  return (
    <>
      <Navbar title="Products" />

      <Sidebar />
      <div className={navBool ? "ProductMove" : "Products"}>
        <div className="ProductsContain">
          <div className="ProductsAdd">
            <div className="ProductsTitle">
              <BiPlusCircle size={25} />
              <p>Add New Product</p>
            </div>
            <div className="ProductInputs">
              <div className="ProductsInput1">
                <label htmlFor="">Name</label>
                <input
                  required
                  type="text"
                  placeholder="Name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>

              <div className="ProductsInput1">
                <label htmlFor="">Availability</label>
                <select
                  name=""
                  id=""
                  value={data.isActive}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setData({ ...data, isActive: true });
                    } else {
                      setData({ ...data, isActive: false });
                    }
                  }}
                >
                  <option value="" disabled>
                    --select--
                  </option>
                  <option value={"true"}>Availble</option>
                  <option value={"false"}>Not Availble</option>
                </select>

                <BsChevronDown className="iconDown1" />
              </div>
            </div>

            <div className="ProductInputs">
              <div className="ProductsInput2">
                <label htmlFor="">Price</label>
                <input
                  value={data.price}
                  type="text"
                  placeholder="0"
                  onChange={(e) => setData({ ...data, price: e.target.value })}
                />
              </div>
              <div className="ProductsInput2">
                <label>Discount Type </label>
                <select
                  value={data.discount_type}
                  onChange={(e) => {
                    if (e.target.value === "none") {
                      setDisabled(true);
                      setData({ ...data, discount_type: e.target.value });
                    } else {
                      setDisabled(false);

                      setData({ ...data, discount_type: e.target.value });
                    }
                  }}
                >
                  <option value="" disabled>
                    discount type
                  </option>
                  <option value="percent">Percent</option>
                  <option value="amount">Amount</option>
                  <option value="none">None</option>
                </select>

                <BsChevronDown className="iconDown2" />
              </div>
              <div className="ProductsInput2">
                <label htmlFor="">Discount</label>
                <input
                  value={data.discount}
                  disabled={disabled}
                  type="text"
                  placeholder="0"
                  onChange={(e) =>
                    setData({ ...data, discount: e.target.value })
                  }
                />
              </div>
              <div className="ProductsInput2">
                <label htmlFor="">Availble Quantity</label>
                <input
                  type="text"
                  value={data.available_quantity}
                  placeholder="0"
                  onChange={(e) =>
                    setData({ ...data, available_quantity: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="ProductInputs">
              <div className="ProductsInput6">
                <label htmlFor="">Category </label>
                <select
                  value={data.category_id}
                  id="select"
                  onChange={(e) =>
                    setData({ ...data, category_id: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Choose category
                  </option>
                  {categoryList.length == 0 ? (
                    <option disabled>No results found</option>
                  ) : (
                    categoryList.map((i) => {
                      return <option value={i.category_id}>{i.name}</option>;
                    })
                  )}
                </select>
                <BsChevronDown className="iconDown" />
              </div>
            </div>

            <div className="ProductInputs">
              <div className="ProductsInput6">
                <label htmlFor="">Short description</label>
                <textarea
                  value={data.discription}
                  maxLength={10000}
                  onChange={(e) =>
                    setData({ ...data, discription: e.target.value })
                  }
                ></textarea>
              </div>
            </div>

            <div className="ProductInputs">
              <div className="ProductsInput6">
                <p style={{ color: "gray" }}>
                  Product image{" "}
                  <span style={{ color: "red" }}> * (Ratio 1:1)</span>
                </p>
                <div class="custom-file-wrp">
                  <div class="filename">{filename}</div>
                  <div className="custom-file">
                    <label htmlFor="fileupload">Browse</label>
                    <input
                      type="file"
                      id="fileupload"
                      onChange={(e) => {
                        handlefile(e);
                        setImgFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="ProductInputs">
              <div className="ProductsInput6">
                <p style={{ color: "gray" }}>
                  Product Images{" "}
                  <span style={{ color: "red" }}> * (Ratio 1:1)</span>
                </p>
                <div class="custom-file-wrp3">
                  <div class="filename3">Choose images</div>
                  <div className="custom-file3">
                    <label htmlFor="fileupload3">Browse</label>
                    <input
                      value={data.images}
                      multiple
                      accept="image/png , image/jpeg , image/webp"
                      type="file"
                      id="fileupload3"
                      onChange={ImageFileListFunction}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="ProductInputs">
              <div className="ProductsInput6">
                <p style={{ color: "gray" }}>Videos</p>
                <div class="custom-file-wrp4">
                  <div class="filename3">Add videos</div>
                  <div className="custom-file4">
                    <label htmlFor="fileupload4">Browse</label>
                    <input
                  value={data.videos}

                      multiple
                      accept=".mp4"
                      type="file"
                      id="fileupload4"
                      onChange={VideoFileListFunction}
                    />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="ProductInputs">
              <div className="ProductsInput6">
                <label htmlFor="">Video Link</label>
                <textarea
                  maxLength={200}
                  onChange={(e) =>
                    setData({ ...data, video_link: e.target.value })
                  }
                ></textarea>
              </div>
            </div>

            <button className="ProductButton" onClick={AddData}>
              Submit
            </button>

            {success && (
              <div className="ShowInfo">
                <BsShieldFillExclamation size={25} />
                <div>
                  <p style={{ marginBottom: "6px" }}>SUCCESS</p>
                  <p>Uploaded Product</p>
                </div>
              </div>
            )}
            {error && (
              <div className="ShowInfoError">
                <BsShieldFillExclamation size={25} />
                <div>
                  <p style={{ marginBottom: "6px" }}>ERROR</p>
                  <p>Error While Uploading</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import "../OrderDetail.css";
import { useParams } from "react-router-dom";
import { BsPhone } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { BiLocationPlus } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useState } from "react";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const OrderDetail = () => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    address: [{ latitude: "", longitude: "" }],
    items: [],
  });

  const ud = useParams();
  const [load, setLoad] = useState(true);
  const GetData = async () => {
    const docRef = doc(db, "orders", ud.id);
    const docSnap = await getDoc(docRef);
    setData(docSnap.data());
    setLoad(false);
  };
  useEffect(() => {
    GetData();
  }, []);

  const n = [];
  data.items.map((i) => {
    n.push(i.price * i.quantity);
  });
  const itemprice = eval(n.join("+"));

  const formatDate = (dateString) => {
    // Create a new Date object from the given date string.
    const date = new Date(dateString);

    // Get the UTC offset of the date.
    const utcOffset = date.getUTCOffset() / 60;

    // Add the UTC offset to the date to get the local date.
    date.setHours(date.getHours() + utcOffset);

    // Format the date in the desired format.
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    // Return the formatted date.
    return formattedDate;
  };

  return (
    <>
      <Navbar title="Order Details" />

      <Sidebar />
      <div className="OrderDetailPage">
        <div className="contain">
          <div className="OrderDetailContain">
            {load ? (
              <>
                <div className="movec">
                  <div class="lds-spinner ">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="OrderDetailBox">
                  <h1>Order Details #{ud.id}</h1>
                  <div className="OrderDetailContain1">
                    <p>Order Note </p>
                    <div>
                      <p style={{ marginBottom: "15px" }}>
                        Payment Method :{" "}
                        {data.payment_method == "bank_transfer"
                          ? "Bank Transfer"
                          : "Cash on Delivery"}
                      </p>
                      <p style={{ marginBottom: "15px" }}>
                        {" "}
                        Order Type : Delivery
                      </p>
                      <p style={{ marginBottom: "15px" }}>
                        {" "}
                        Order Time :{" "}
                        {new Date(data.date.seconds * 1000).toDateString()}
                      </p>
                      <p style={{ marginBottom: "15px" }}>
                        {" "}
                        Order Time :{" "}
                        {new Date(data.date.seconds * 1000).toDateString()}
                      </p>
                      {data.payment_method == "bank_transfer" ? (
                        <div className="payment-details">
                          <div>
                            <span className="bank">Amount Deposited : </span>
                            <span>
                              {data.payment_method_details.amount_deposited}
                            </span>
                          </div>
                          <div>
                            <span className="bank">Bank Name : </span>
                            <span>{data.payment_method_details.bank_name}</span>
                          </div>
                          <div>
                            <span className="bank">Depositor Name : </span>
                            <span>
                              {data.payment_method_details.depositor_name}
                            </span>
                          </div>

                          <div>
                            <span className="bank">Receipt Photo : </span>
                            <a
                              href={data.payment_method_details.receipt_photo}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={data.payment_method_details.receipt_photo}
                                alt="Receipt"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        {data.items.map((i) => {
                          return (
                            <TableRow
                              key={i.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                <div className="OrderDetailImage">
                                  <img
                                    style={{ marginRight: "9px" }}
                                    src={i.cover_pic}
                                    alt=""
                                  />
                                  <p style={{ color: "gray" }}>{i.name}</p>
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <p>{i.price} Br</p>
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <p>{i.quantity}</p>{" "}
                              </TableCell>
                              <TableCell align="left">
                                <p>{i.quantity * i.price} Br</p>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="OrderDetailPrice">
                    <div className="MainPricedata">
                      <div className="OrderDetailPriceItemName">
                        <p>Item price: </p>
                        <p>Addon Cost: </p>
                        <p>Subtotal: </p>
                      </div>
                      <div className="OrderDetailPriceItem">
                        <p>{itemprice} Br</p>
                        <p> 0 Br</p>
                        <p> {itemprice} Br</p>
                      </div>
                    </div>

                    <div className="MainPricedata">
                      <div className="OrderDetailPriceItemName">
                        <p>Coupon Discount: </p>
                        <p>Discount: </p>
                        <p>VAT/TAX: </p>
                        <p>Total: </p>
                      </div>
                      <div className="OrderDetailPriceItem">
                        <p>
                          {" "}
                          {data.coupon?.code == null ? 0 : data.discount} Br
                        </p>
                        <p>
                          {data.coupon?.code == null
                            ? 0
                            : itemprice - data.discount}{" "}
                          Br
                        </p>
                        <p> 0 Br</p>
                        <p>
                          {" "}
                          {data.coupon?.code == null
                            ? itemprice
                            : itemprice - data.discount}{" "}
                          Br
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="OrderCustomer">
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      borderBottom: "1px solid gray",
                      marginBottom: "15px",
                      padding: "29px 10px",
                    }}
                  >
                    <img
                      style={{ width: "30px" }}
                      src="https://th.bing.com/th/id/R.aee6adef085a77dfa4708f3fd4a1ffb5?rik=nj%2buNqgLIJU0JQ&pid=ImgRaw&r=0"
                      alt=""
                    />
                    <p>{data.user?.name}</p>
                  </div>

                  <div style={{}}>
                    <p style={{ marginBottom: "15px", padding: "25px 10px" }}>
                      Contact Information
                    </p>
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginBottom: "15px",
                        padding: "0 10px",
                      }}
                    >
                      <BsPhone size={25} color="gray" />
                      <p>{data.user?.phone}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        borderBottom: "1px solid gray",
                        padding: "29px 10px",
                      }}
                    >
                      <MdEmail size={25} color="gray" />
                      <p>{data.email ? data.email : "No Email"}</p>
                    </div>
                  </div>
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    href={`https://www.google.com/maps/dir//${data.deliveryAddress?.latitude},${data.deliveryAddress?.longtude}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "25px 10px 0px 10px",
                        color: "blue",
                      }}
                    >
                      <HiLocationMarker size={25} color="blue" />

                      <p>{data.deliveryAddress?.street_address || "None"}</p>
                    </div>
                  </a>

                  <div className="MainPricedata">
                    <div className="OrderDetailPriceItemName">
                      <p>Country : </p>
                      <p>City : </p>
                      <p>State : </p>
                      <p>Street Address: </p>
                    </div>
                    <div className="OrderDetailPriceItem">
                      <p>{data.deliveryAddress?.country || "None"} </p>
                      <p style={{ color: "black" }}>
                        {data.deliveryAddress?.city || "None"}
                      </p>
                      <p> {data.deliveryAddress?.state || "None"}</p>
                      <p> {data.deliveryAddress?.street_address || "None"}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
